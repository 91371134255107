export const useSlides = () => {

  let controlComponent;
  let navigationComponent ;
  const slidesCount = ref(0);

  const initSlides = (control, navigation) => {
    controlComponent = control;
    navigationComponent = navigation;
  }
  const scrollToSlide = (slide) => {
    controlComponent.value.scrollToSlide(slide)
  }

  const setSlide = (slide) => {
    navigationComponent.value.setSlide(slide);
  }

  const setSlides = (slides) => {
    slidesCount.value = slides;
  }

  return {
    initSlides,
    scrollToSlide,
    setSlide,
    setSlides,
    slidesCount
  }
}
